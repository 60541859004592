






















































































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import SaleForm from '@/components/SaleForm.vue'
import SaleItems from '@/views/saleitems/List.vue'
import RequisitionsTable from '@/components/Sales/RequisitionsTable.vue'
import PurchaseOrders from '@/components/Sales/PurchaseOrders.vue'
import Acquirers from '@/components/Sales/Acquirers.vue'
import Sellers from '@/components/Sales/Sellers.vue'
import UnsoldBills from '@/components/Sales/UnsoldBills.vue'
import SaleDocuments from '@/components/Sales/SaleDocuments.vue'
import SendMailForm from '@/components/SendMailForm.vue'
import BrouillardForm from '@/components/BrouillardForm.vue'
import PlateformForm from '@/components/PlateformForm.vue'
import { ApiResponse, Sale, RequisitionItem, FormContact, Plateform } from '@/interfaces'
import { mapState } from 'vuex'
import { stockStatuses } from '@/formHelpers'

interface TabObject {
  _props: Record<string, string>
}

interface SendMailData {
  contacts?: FormContact[]
  customText?: string
  structure?: number | null
  chosenModel?: number | null
}

export default Vue.extend({
  components: {
    Nav,
    SaleForm,
    SaleItems,
    RequisitionsTable,
    PurchaseOrders,
    Acquirers,
    Sellers,
    SaleDocuments,
    SendMailForm,
    UnsoldBills,
    BrouillardForm,
    PlateformForm,
  },
  data() {
    return {
      busy: true,
      displayAlert: false,
      saveSaleTrigger: false,
      sale: {} as Sale,
      saleId: null as number | null,
      title: null as string | null,
      activeTab: 'infos',
      triggerRefreshSaleItems: false,
      saleItems: [] as RequisitionItem[],
      soldItemsLength: 0,
      sendMailData: {} as SendMailData,
      showSaleSendEmailModal: false,
      showBrouillardModal: false,
      showPlateformModal: false,
      sendType: '',
      itemFilter: '',
      stockStatuses,
      fetchPurchaseOrders: false,
      addons: 0,
      sendPlateform: {} as Plateform,
    }
  },
  computed: {
    ...mapState(['user']),
    saleTitle(): string {
      return this.title ?? this.sale.title ?? 'Vente'
    },
  },
  watch: {
    user(newVal) {
      this.addons = newVal.client?.addons ?? 0
    },
  },
  mounted() {
    // eslint-disable-next-line no-console
    //console.log("mounted Edit sale")

    this.refreshSale()

    if (this.$route.query.tab && this.$route.query.tab !== null) {
      this.activeTab = this.$route.query.tab as string
    }
    if (this.user) {
      this.addons = this.user.client?.addons ?? 0
    }
  },
  methods: {
    refreshSaleItems() {
      this.triggerRefreshSaleItems = !this.triggerRefreshSaleItems
    },
    refreshSale() {
      const saleId = this.$route.params.saleId

      if (saleId) {
        this.busy = true
        this.displayAlert = false

        this.$api
          .get(`/sale/${saleId}`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.sale = apiResponse.data
            this.soldItemsLength = this.sale?.soldItems ?? 0
          })
          .catch(() => {
            this.displayAlert = true
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    saleCreated(sale: Sale) {
      if (sale.id) {
        this.$router.push({
          name: 'sale_edit',
          params: {
            saleId: sale.id ? sale.id.toString() : '',
          },
        })
      }
    },
    updateSale(sale: Sale) {
      this.sale = sale
    },
    updateTitle(title: string) {
      this.title = title
    },
    fetchSaleItems(saleId: number) {
      ; (this.$refs.saleItems as InstanceType<typeof SaleItems>).fetchSaleItems(saleId, true)
    },
    updateSaleItems(items: RequisitionItem[]) {
      this.saleItems = items
    },
    updatePurchaseOrders() {
      this.fetchPurchaseOrders = !this.fetchPurchaseOrders
    },
    deleteSale(sale: Sale) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer cette vente ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/sale/${sale.id}`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.$router.push({ name: 'sales' })
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
      })
    },
    changeTab(tab: TabObject) {
      if (!this.busy) {
        this.activeTab = tab._props.name ?? 'infos'
        // Here we use pushState as using Vue's router would trigger a page reload
        history.pushState(null, '', `${this.$route.path}?tab=${this.activeTab}`)

        this.triggerSave()
      }
    },
    triggerSave() {
      this.saveSaleTrigger = !this.saveSaleTrigger

      return true
    },
    goBack() {
      this.triggerSave()
      this.$router.back()
    },
    getTitle(sale: Sale): string {
      return sale.title ?? 'Vente'
    },
    getNumItems(sale: Sale): string {
      if (sale.numItems) {
        return `Lots (${sale.numItems})`
      } else {
        return 'Lots'
      }
    },
    createSaleDrouotOnline() {
      this.sendPlateform.plateform = "drouot"
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir créer la vente sur Drouot Online ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      )
        .then(() => {
          this.$api
            //.post(`/sale/${this.$route.params.saleId}/drouotonline`)
            .post(`/sale/${this.$route.params.saleId}/publishToPlateform`, this.sendPlateform)
            .then(() => {
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'La vente a bien été transmise : En cours de traitement chez Drouot...',
              })
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message == 'Failed to fetch user config.') {
                  this.$notify({
                    type: 'error',
                    title: 'Erreur',
                    message:
                      'Une erreur de configuration a été détectée sur votre compte. Merci de contacter un administrateur.',
                  })
                } else {
                  this.$notify({
                    type: 'error',
                    title: 'Erreur',
                    message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                  })
                }
              }
            })
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
    },
    createSaleMoniteurLive() {
      this.sendPlateform.plateform = "moniteur"
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir créer la vente sur Moniteur Live ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      )
        .then(() => {
          this.$api
            //.post(`/sale/${this.$route.params.saleId}/moniteurlive`)
            .post(`/sale/${this.$route.params.saleId}/publishToPlateform`, this.sendPlateform)
            .then(() => {
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'Opération réalisée avec succès !',
              })
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message == 'Failed to fetch user config.') {
                  this.$notify({
                    type: 'error',
                    title: 'Erreur',
                    message:
                      'Une erreur de configuration a été détectée sur votre compte. Merci de contacter un administrateur.',
                  })
                } else {
                  this.$notify({
                    type: 'error',
                    title: 'Erreur',
                    message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                  })
                }
              }
            })
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
    },
    openXML(str: string) {
      var filename = 'interencheres.xml'
      var pseudoelement = document.createElement('a')
      var blob = new Blob([str], { type: 'text/plain' })

      pseudoelement.setAttribute('href', window.URL.createObjectURL(blob))
      pseudoelement.setAttribute('download', filename)

      pseudoelement.dataset.downloadurl = ['text/plain', pseudoelement.download, pseudoelement.href].join(':')
      pseudoelement.draggable = true
      pseudoelement.classList.add('dragout')

      pseudoelement.click()
    },
    generateInterencheresXML() {
      this.sendPlateform['plateform'] = "interencheres"
      this.$confirm("Êtes-vous sûr(e) de vouloir créer le fichier d'import Interenchères ?", 'Confirmation', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning',
      }).then(() => {
        // Checking first if two items have the same number, as it should not be allowed
        this.$api
          .get(`/sale/${this.sale.id}/items`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            const items = apiResponse.data

            let itemsIds = [] as string[]
            items.forEach((item: RequisitionItem) => {
              if (item.itemNumber) {
                itemsIds.push(item.itemNumber)
              }
            })

            this.$api
              //.post(`/sale/${this.$route.params.saleId}/interencheres`)
              .post(`/sale/${this.$route.params.saleId}/publishToPlateform`, this.sendPlateform)
              .then((response) => {
                const apiResponse = response.data as ApiResponse
                this.openXML(apiResponse.data)
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.message == 'Failed to fetch user config.') {
                    this.$notify({
                      type: 'error',
                      title: 'Erreur',
                      message:
                        'Une erreur de configuration a été détectée sur votre compte. Merci de contacter un administrateur.',
                    })
                  } else {
                    this.$notify({
                      type: 'error',
                      title: 'Erreur',
                      message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                    })
                  }
                }
              })
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
      })
    },
    exportFullLotsAsCSV() {
      this.$api
        .post(`/sale/${this.sale?.id}/item-export/full`, {
          params: {
            filter: this.itemFilter,
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once`
          //const documentUrl = `${process.env.VUE_APP_API_URL}/document/sale-export/${this.$route.params.saleId}/${this.user.client.id}/download`
          window.open(documentUrl, '_blank')
        })
    },
    exportLotsAsCSV() {
      this.$api
        .post(`/sale/${this.sale?.id}/item-export/exportimport`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once`
          //const documentUrl = `${process.env.VUE_APP_API_URL}/document/sale-export/${this.$route.params.saleId}/${this.user.client.id}/download`
          window.open(documentUrl, '_blank')
        })
    },    
    exportPhotos() {
      this.$api
        .post(`/sale/${this.$route.params.saleId}/export-photos`)
        .then(() => {
          const documentUrl = `${process.env.VUE_APP_API_URL}/document/sale/${this.$route.params.saleId}/${this.user.client.id}/download`
          window.open(documentUrl, '_blank')
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
    },
    setItemFilter(filter: string) {
      this.itemFilter = filter
    },
    openSendEmailModal(type: string) {
      this.showSaleSendEmailModal = true
      this.sendType = type
    },
    closeSendEmailModal() {
      this.showSaleSendEmailModal = false
      this.sendMailData = {}
      this.sendType = ''
        ; (this.$refs.saleItems as InstanceType<typeof SaleItems>).toggleCheckAll(false)
    },
    openBrouillardModal() {
      this.showBrouillardModal = true
    },
    closeBrouillardModal() {
      this.showBrouillardModal = false
    },    
    openPlateformModal() {
      this.showPlateformModal = true
    },    
    closePlateformModal() {
      this.showPlateformModal = false
    },    
    sendEmailSale(sendMailData: SendMailData) {
      const data = {
        ...sendMailData,
        type: this.sendType,
      }
      let success = false
      this.$api
        .post(`/sale/${this.sale.id}/mass-sale-email`, data)
        .then(() => {
          let message =
            this.sendType === 'before'
              ? "Mails d'avant-vente envoyés avec succès !"
              : 'Mails de bilan de vente envoyés avec succès !'
          this.$notify({
            type: 'success',
            title: 'Succès',
            message,
          })
          success = true
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          if (success) {
            this.closeSendEmailModal()
          }
        })
    },
    generateDepouillement(type: string, status = '') {
      this.$api
        .post(`/sale/${this.sale?.id}/depouillement`, {
          type,
          status,
        })
        .then(() => {
          const documentUrl = `${process.env.VUE_APP_API_URL}/document/sale/${this.sale?.id}/download?contentd=inline`
          window.open(documentUrl, '_blank')
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
        })
    },
    generateDocumentAdagp(type: string, status = '') {
      this.$api
        .post(`/sale/${this.sale?.id}/adagp`, {
          type,
          status,
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          if (apiResponse.success === true) {
            const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once`
            window.open(documentUrl, '_blank')
          }
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
        })
    },
    // generateAfterSaleSynthesis(accountData: boolean) {
    //   if (!this.busy) {
    //     this.busy = true

    //     this.$api
    //       .post(`/sale/${this.sale?.id}/after-sale-synthesis?accountData=${accountData}`)
    //       .then((response) => {
    //         const apiResponse = response.data as ApiResponse
    //         //const documentUrl = `${process.env.VUE_APP_API_URL}/document/daybook/${apiResponse.data.filename}/${this.user.client.id}/download`
    //         const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once?contentd=inline`
    //         window.open(documentUrl, '_blank')
    //       })
    //       .catch(() => {
    //         this.$notify({
    //           type: 'error',
    //           title: 'Erreur',
    //           message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
    //         })
    //       })
    //       .finally(() => {
    //         this.busy = false
    //       })
    //   }
    // },
    refreshDocuments(documents: Document[]) {
      ; (this.$refs.documents as InstanceType<typeof SaleDocuments>).refreshDocuments(documents)
    },
    addFile() {
      document.getElementById('inputRequisitionItemsImport')?.click()
    },    
    updateInputFile(event: Event) {
      const inputFiles = (event.target as HTMLInputElement).files
      if (!inputFiles) {
        this.$notify({
          type: 'error',
          title: 'Erreur',
          message: 'Fichier absent.',
        })
        return
      }
      this.importRequisitionItems(inputFiles[0])
    },    
    importRequisitionItems(file: File) {
      if (!file) return
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', () => {
        if (reader.result) {
          const data = {
            filename: file.name,
            data: reader.result.toString().split(',')[1],
          }

          this.busy = true

          this.$api
            .post(
              `/sale/${this.$route.params.saleId}/import`,
              data
            )
            .then((response) => {
              const apiResponse = response.data as ApiResponse
              this.sale = apiResponse.data
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'Import réalisé avec succès !',
              })              
            })
            .catch((error) => {
                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message:
                    error.response.data.message ??
                    'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                })
            })
            .finally(() => {
              this.busy = false
              this.refreshSaleItems()
            })
        }
      })
    },    
  },
})
