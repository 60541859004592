



































import Vue from 'vue'
import { ApiResponse, Plateform } from '@/interfaces'
import { mapState } from 'vuex'


export default Vue.extend({
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    saleId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      visibleModal: false,
      plateforms: [] as Plateform[],
      sendPlateform: {} as Plateform,
    }
  },
  computed: {
    ...mapState(['user']),
    modalTitle() {
      return 'Publier sur une plateforme'
    },
  },
  watch: {
    user(newVal) {
      if (Array.isArray(newVal.client.config.plateformsPublicInfos)) {
        this.plateforms = []
        newVal.client.config.plateformsPublicInfos.forEach((plateform: Plateform) => {
          this.plateforms.push({
            id: plateform.id,
            label: plateform.label,
          })
        })

        // If there's only one structure, it should be selected by default
        if (this.plateforms.length == 1) {
          this.sendPlateform = this.plateforms[0]
        }
      }
    },      
    showModal(newVal) {
      this.visibleModal = newVal
    },
  },
  mounted() {
    if (this.user) {
      // Update structures
      this.plateforms = this.user.client.plateforms

      if (Array.isArray(this.user.client.config.plateformsPublicInfos)) {
          this.plateforms = []
          this.user.client.config.plateformsPublicInfos.forEach((plateform: Plateform) => {
          this.plateforms.push({
            id: plateform.id,
            label: plateform.label,
          })
        })
      }
                
      // If there's only one structure, it should be selected by default
      if (this.plateforms.length == 1) {
        this.sendPlateform = this.plateforms[0]
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    noSubmit() {
      return false
    },
    submit() {
      this.$emit('closeDialog')
      this.publishToPlateform()
    },
    publishToPlateform() {
      if (!this.busy) {
        this.busy = true

        this.$api
          .post(`/sale/${this.saleId}/publishToPlateform`, this.sendPlateform)
          .then((response) => {
              const apiResponse = response.data as ApiResponse
              if(apiResponse.data){
                this.openXML(apiResponse.data)
              }
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'Opération réalisée avec succès !',
              })                
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message == 'Failed to fetch user config.') {
                  this.$notify({
                    type: 'error',
                    title: 'Erreur',
                    message:
                      'Une erreur de configuration a été détectée sur votre compte. Merci de contacter un administrateur.',
                  })
                } else {
                  this.$notify({
                    type: 'error',
                    title: 'Erreur',
                    message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                  })
                }
              }
            })
          .finally(() => {
            this.busy = false
          })
      }
    },      
    disabledForm() {
      return false
    },
    openXML(str: string) {
      var filename = 'interencheres.xml'
      var pseudoelement = document.createElement('a')
      var blob = new Blob([str], { type: 'text/plain' })

      pseudoelement.setAttribute('href', window.URL.createObjectURL(blob))
      pseudoelement.setAttribute('download', filename)

      pseudoelement.dataset.downloadurl = ['text/plain', pseudoelement.download, pseudoelement.href].join(':')
      pseudoelement.draggable = true
      pseudoelement.classList.add('dragout')

      pseudoelement.click()
    },      
  },
})
